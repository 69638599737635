import React, { lazy, Suspense, useContext } from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { Roles } from 'context/Roles.context';

const BusinessRulesManager = lazy(() =>
  import('./pages/BusinessRulesManager/BusinessRulesManager'),
);
const Edit = lazy(() => import('./pages/Edit/Edit'));
const Reports = lazy(() => import('./pages/Reports/Reports'));
const TestingTool = lazy(() => import('./pages/TestingTool/TestingTool'));
const AdminConsole = lazy(() => import('./pages/AdminConsole/AdminConsole'));
const GradeHierarchy = lazy(() => import('./pages/GradeHierarchy/GradeHierarchy'));
const ColorConfiguration = lazy(() => import('./pages/ColorConfiguration/ColorConfiguration'));
const ErrorNotFound = lazy(() => import('./pages/ErrorNotFound/ErrorNotFound'));

const Router = () => {
  const auth = useAuth();
  const { permissions } = useContext(Roles);

  switch (auth.activeNavigator) {
    case 'signoutRedirect':
    case 'signinSilent':
    case 'signinRedirect':
      return <div>Redirecting to Login...</div>;
  }

  if (auth.isLoading) {
    return <LoadingSpinner fullScreen />;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (!auth.isAuthenticated) {
    const pathname = window.location.pathname;
    if (pathname !== '/') {
      sessionStorage.setItem('cube-pathname', window.location.pathname);
    }
    auth.signinRedirect();
    return null;
  }

  const getReportRoutes = () => {
    if (!Object.values(permissions.reports.tableau).some((a) => a === true)) return <></>;

    return (
      <Route path="/reports" element={<Outlet />}>
        {!!permissions?.reports?.tableau?.analytics && (
          <Route
            path="usage-analytics"
            element={<Reports title="Usage Analytics" report="sfeanalytics" />}
          />
        )}
        {!!permissions?.reports?.tableau?.simulation && (
          <Route
            path="simulation-dashboard"
            element={<Reports title="Simulation Dashboard" report="sfedashboard" />}
          />
        )}
      </Route>
    );
  };

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingSpinner fullScreen />}>
        <Routes>
          <Route index path="/" element={<BusinessRulesManager />} />
          <Route path="/category" element={<Outlet />}>
            <Route path=":id" element={<Edit />} />
            <Route path=":id/edit" element={<Edit />} />
          </Route>
          <Route path="/match-group" element={<Outlet />}>
            <Route path=":id" element={<Edit />} />
            <Route path=":id/edit" element={<Edit />} />
            <Route path=":id/new" element={<Edit />} />
          </Route>

          {!!permissions?.search?.view && (
            <Route index path="/testing-tool" element={<TestingTool />} />
          )}
          {!!permissions?.search?.view && (
            <Route index path="/admin-console" element={<AdminConsole />} />
          )}
          {!!permissions?.search?.view && (
            <Route index path="/grade-hierarchy" element={<GradeHierarchy />} />
          )}
          {!!permissions?.search?.view && (
            <Route index path="/colors" element={<ColorConfiguration />} />
          )}
          {getReportRoutes()}
          <Route path="*" element={<ErrorNotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
