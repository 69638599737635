const url = window.location.origin;

export const getEnv = () => {
  switch (true) {
    case url.includes('one.cube.toyota.com'):
      return 'prod';
    case url.includes('one.cubeuat.toyota.com'):
      return 'uat';
    case url.includes('one.cubeqa.toyota.com'):
      return 'qa';
    case url.includes('one.cubedev.toyota.com'):
    case url.includes('localhost'):
    default:
      return 'dev';
  }
};

export const getBaseURL = () =>
  ({
    dev: 'one.cubedev.toyota.com',
    qa: 'one.cubeqa.toyota.com',
    uat: 'one.cubeuat.toyota.com',
    prod: 'one.cube.toyota.com',
  }[getEnv()]);

export const getVspecParams = () =>
  ({
    dev: {
      vSpecGuestUrl: 'https://guest.dealer.qa.toyota.com',
      vSpecHashUrl: 'https://api.rti.qa.toyota.com',
      vSpecXApiKey: '899a5fe823ed56c5860c5e5ab0eb170c',
    },
    qa: {
      vSpecGuestUrl: 'https://guest.dealer.qa.toyota.com',
      vSpecHashUrl: 'https://api.rti.qa.toyota.com',
      vSpecXApiKey: '899a5fe823ed56c5860c5e5ab0eb170c',
    },
    uat: {
      vSpecGuestUrl: 'https://guest.dealer.qa.toyota.com',
      vSpecHashUrl: 'https://api.rti.qa.toyota.com',
      vSpecXApiKey: '899a5fe823ed56c5860c5e5ab0eb170c',
    },
    prod: {
      vSpecGuestUrl: 'https://guest.dealer.toyota.com',
      vSpecHashUrl: 'https://api.rti.toyota.com',
      vSpecXApiKey: '89ad76674ea851fdb45f243ae30ec351',
    },
  }[getEnv()]);
