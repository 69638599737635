import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useAuth } from 'react-oidc-context';
import { getVspecParams } from '../utils/environment';

export const VSpec = createContext({});

const VSpecProvider = ({ children }) => {
  const auth = useAuth();

  const [vSpecHashKey, setVSpecHashKey] = useState(null);

  useEffect(() => {
    const token = auth?.user?.access_token;
    if (!token) return;

    const headers = {
      Authorization: `Bearer ${token}`,
      'x-api-key': getVspecParams()['vSpecXApiKey'],
    };

    axios
      .get(
        `${getVspecParams()['vSpecHashUrl']}/token-service?tokenName=vspec&systemName=System-SFE`,
        { headers },
      )
      .then((response) => {
        setVSpecHashKey(response?.data?.tokenDetails?.hashKey || null);
      })
      .catch((e) => e);
  }, [auth?.user?.access_token]);

  return <VSpec.Provider value={{ vSpecHashKey }}>{children}</VSpec.Provider>;
};

VSpecProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default VSpecProvider;
